import dayjs from 'dayjs';
import qs from 'qs';

import { toAbsoluteUrl } from '@src/_metronic';

import { Vehicle } from '@app/store/stock/stock.type';

const { REACT_APP_AUTOSPHERE_URL, REACT_APP_AUTOSPHERE_BE_URL, REACT_APP_FLUIDITY_URL } = process.env;

export const MAX_VEHICLES = 3;

/**
 * Format lead vehicles
 *
 */
export const formatLeadVehicles = (lead: { leadElements: { vehicle: Vehicle }[] }) => {
    if (!lead?.leadElements) {
        return [];
    }

    return lead.leadElements.map((element) => {
        const vehicle = element?.vehicle ? { ...element.vehicle } : ({} as Vehicle);

        if (vehicle) {
            vehicle.sku = vehicle.vehicleInternalId;
        }

        return vehicle;
    });
};

export const getVehicleInternalIdTemplate = (vehicle: Vehicle, defaultValue = '-') => {
    if (vehicle?.sku) {
        return vehicle.sku;
    } else if (vehicle?.vehicleInternalId) {
        return vehicle.vehicleInternalId;
    }
    return defaultValue;
};

const formatRegistrationNumber = (registration: string, defaultValue = '-') => {
    if (!registration) {
        return defaultValue;
    }
    return `${registration.substr(0, 2)}-${registration.substr(2, 3)}-${registration.substr(5, 2)}`;
};

export const getRegistrationTemplate = (vehicle: Vehicle, defaultValue = '-') => {
    if (vehicle?.license_number) {
        return vehicle.license_number.includes('-')
            ? vehicle.license_number
            : formatRegistrationNumber(vehicle.license_number, defaultValue);
    } else if (vehicle?.registration) {
        return vehicle.registration.includes('-')
            ? vehicle.registration
            : formatRegistrationNumber(vehicle.registration, defaultValue);
    }
    return defaultValue;
};

export const getRegistrationOrSerialNumberTemplate = (vehicle: Vehicle, defaultValue = '-') => {
    if (vehicle?.license_number) {
        return vehicle.license_number;
    } else if (vehicle?.registration) {
        return vehicle.registration;
    } else if (vehicle?.serialNumber) {
        return vehicle.serialNumber;
    }
    return defaultValue;
};

export const getWarrantyTemplate = (vehicle: Vehicle, defaultValue = '-') => {
    if (vehicle?.warranty_label) {
        return vehicle.warranty_label;
    } else if (vehicle?.warranty) {
        return vehicle.warranty;
    }
    return defaultValue;
};

export const getRecoverableVat = (vehicle: Vehicle, defaultValue = '-') => {
    if (typeof vehicle?.recoverable_vat === 'boolean') {
        return vehicle.recoverable_vat ? 'Oui' : 'Non';
    } else if (typeof vehicle?.recoverableVat === 'boolean') {
        return vehicle.recoverableVat ? 'Oui' : 'Non';
    }
    return defaultValue;
};

export const getPictureTemplate = (vehicle: Vehicle, defaultValue: string | null = null) => {
    if (vehicle?.default_image) {
        return vehicle.default_image;
    } else if (vehicle?.image) {
        return vehicle.image;
    } else if (vehicle?.defaultImage) {
        return vehicle.defaultImage;
    }
    return defaultValue !== null ? defaultValue : toAbsoluteUrl('/media/bg/default-veh-bg.svg');
};

export const getBrandTemplate = (vehicle: Vehicle, defaultValue = '-') => {
    if (vehicle?.brand) {
        return vehicle.brand;
    } else if (vehicle?.make) {
        return vehicle.make;
    }
    return defaultValue;
};

export const getUrl = (vehicle: Vehicle, defaultValue = '-', isContextEFB = false) => {
    const utmSource = '?utm_source=Automanager&utm_medium=CRM&utm_campaign=Clic_to_autosphere';
    if (vehicle?.seo_title) {
        return `${isContextEFB ? `${REACT_APP_AUTOSPHERE_BE_URL}/fr/detail/` : `${REACT_APP_AUTOSPHERE_URL}/fiche/`}${
            vehicle.seo_title
        }${utmSource}`;
    } else if (vehicle?.seoTitle) {
        return `${isContextEFB ? `${REACT_APP_AUTOSPHERE_BE_URL}/fr/detail/` : `${REACT_APP_AUTOSPHERE_URL}/fiche/`}${
            vehicle.seoTitle
        }${utmSource}`;
    }
    return defaultValue;
};
export const getFluidityUrl = (vehicle: Vehicle, defaultValue = '-') => {
    if (vehicle?.seo_title) {
        return `${REACT_APP_FLUIDITY_URL}/fiche/${vehicle.seo_title}`;
    } else if (vehicle?.seoTitle) {
        return `${REACT_APP_FLUIDITY_URL}/fiche/${vehicle.seoTitle}`;
    }
    return defaultValue;
};

export const getFuelTypeTemplate = (vehicle: Vehicle) => {
    if (vehicle?.fuel_type) {
        return vehicle.fuel_type;
    } else if (vehicle?.fuelType) {
        return vehicle.fuelType;
    }
    return '-';
};

export const getGearsTypeTemplate = (vehicle: Vehicle) => {
    if (vehicle?.gears_type) {
        return vehicle.gears_type;
    } else if (vehicle?.gearsType) {
        return vehicle.gearsType;
    } else if (vehicle?.gearbox) {
        return vehicle?.gearbox;
    }
    return '-';
};

export const getProductionYearTemplate = (vehicle: Vehicle, defaultValue = '-') => {
    if (vehicle?.year) {
        return vehicle.year;
    } else if (vehicle?.productionYear) {
        return vehicle.productionYear;
    } else if (vehicle?.dateFirstRegistration) {
        return dayjs(vehicle?.dateFirstRegistration).format('YYYY');
    }
    return defaultValue;
};

export const getIsFluidity = (vehicle: Vehicle) => {
    if (vehicle?.isFluidity) {
        return vehicle.isFluidity;
    } else if (vehicle?.is_fluidite) {
        return vehicle.is_fluidite;
    }
    return false;
};

export const getRefDealershipId = (vehicle: Vehicle) => {
    if (vehicle?.dealershipId) {
        return vehicle.dealershipId;
    } else if (vehicle?.dealer_id) {
        return vehicle.dealer_id;
    }
    return false;
};

export const getPhotosDefaults = (vehicle: Vehicle) => {
    if (vehicle?.photosDefaults) {
        return vehicle.photosDefaults;
    } else if (vehicle?.photos_defaults) {
        return vehicle.photos_defaults;
    }
    return [];
};

export const getSource360 = (vehicle: Vehicle) => {
    if (vehicle?.source360) {
        return vehicle.source360;
    } else if (vehicle?.source_360) {
        return vehicle.source_360;
    }
    return null;
};

export const getPlayer360 = (vehicle: Vehicle) => {
    if (vehicle?.player360) {
        return vehicle.player360;
    } else if (vehicle?.player_360) {
        return vehicle.player_360;
    }
    return null;
};

export const getNbPhotos = (vehicle: Vehicle) => {
    if (vehicle?.nbImages) {
        return vehicle.nbImages;
    } else if (vehicle?.nb_photos) {
        return vehicle.nb_photos;
    }
    return null;
};

export const getEligibleCredit = (vehicle: Vehicle) => {
    if ('is_eligible_credit' in vehicle) {
        return vehicle?.is_eligible_credit;
    } else if ('credit' in vehicle) {
        return vehicle?.credit;
    }
    return false;
};

export const getEligibleLOA = (vehicle: Vehicle) => {
    if ('is_eligible_loa' in vehicle) {
        return vehicle?.is_eligible_loa;
    } else if ('loa' in vehicle) {
        return vehicle?.loa;
    }
    return false;
};

export const getEligibleClassic = (vehicle: Vehicle) => {
    if ('is_eligible_classic' in vehicle) {
        return vehicle?.is_eligible_classic;
    }
    return false;
};

export const getEligibleBalloon = (vehicle: Vehicle) => {
    if ('is_eligible_balloon' in vehicle) {
        return vehicle?.is_eligible_balloon;
    }
    return false;
};

export const getHasCredit = (vehicle: Vehicle) => {
    if ('finance_credit' in vehicle) {
        return vehicle?.finance_credit > 0;
    }
    return false;
};

export const getHasLOA = (vehicle: Vehicle) => {
    if ('finance_loa' in vehicle) {
        return vehicle?.finance_loa > 0;
    }
    return false;
};

export const getAdvancedPriceTagLink = (params: any) => {
    const { REACT_APP_STOCK_URL } = process.env;

    return `${REACT_APP_STOCK_URL}/price-tag?${qs.stringify(params)}`;
};

export const getPriceTagLink = (vehicle: Vehicle) => {
    const params = {
        mode: 'simple',
        internal_id: vehicle.sku,
        comptant: true,
        location: getEligibleLOA(vehicle),
        credit: getEligibleCredit(vehicle),
    };
    return getAdvancedPriceTagLink(params);
};

export const getPublishDays = (vehicle: Vehicle) =>
    dayjs().diff(dayjs(vehicle?.date_first_publication || undefined).format('YYYY-MM-DD HH:mm::ss'), 'day');
