import bootstrapPlugin from '@fullcalendar/bootstrap';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import * as dayjs from 'dayjs';
import { Divider } from 'primereact/divider';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useLayoutContext } from '@src/_metronic';
import KTUtil from '@src/_metronic/_assets/js/util';
import { Intl, Locale } from '@src/_metronic/i18n/I18nProvider';

import usePermissions from '@app/hooks/usePermissions';

import { fetchSchedules, updateAppointment } from '@app/store/schedule/schedule.thunk';

import EventCaption from '@app/partials/content/Agenda/Event.Caption';
import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import toast from '@app/partials/content/Toast';

import DealershipVendors from '../customers/Partials/DealershipVendors';

import { apiToCalendarObject, calendarToApiObject } from './partials/Event.Handler';
import EventModal from './partials/Event.Modal';
import EventRender from './partials/Event.Render';
import MobileCaptionModal from './partials/MobileCaption.Modal';
import MobileDealershipVendorModal from './partials/MobileDealershipVendor.Modal';
import ModalAlert from './partials/ModalAlert';

import './agenda.scss';

const Agenda = React.forwardRef(
    (
        {
            className,
            bodyClassName,
            canSelectUser,
            weekView,
            selectable,
            onSelect,
            slotDuration,
            height,
            setDatesSetSelected,
            defaultVendor,
        },
        calendar,
    ) => {
        const innerRef = useRef();
        const history = useHistory();
        const dispatch = useDispatch();
        const [teammate, setTeammate] = useState({});
        const [currentDate, setCurrentDate] = useState(null);
        const [_teammates, setTeammates] = useState([]);
        const [businessHours, setBusinessHours] = useState([]);
        const [events, setEvents] = useState(null);
        const [event, setEvent] = useState(null);
        const { hasPermission } = usePermissions();
        const { availabilities, appointments, microsoftConnection, loading } = useSelector((state) => state.schedule);
        const { userData } = useSelector((state) => state.auth);
        const [showModalAlert, setShowModalAlert] = useState(false);
        const [showMobileCaptionModal, setShowMobileCaptionModal] = useState(false);
        const [showMobileDealershipModal, setShowMobileDealershipModal] = useState(false);
        const context = useLayoutContext();

        const eventScheduleHandler = (arg) => {
            const html = `
            <h3>
                <stong>${arg.event.title}</stong>
            </h3>
            </br>
            <p>
                ${dayjs(arg.oldEvent.start).format('YYYY-MM-DD')}
                ${dayjs(arg.oldEvent.start).format('HH:mm')}
                ${dayjs(arg.oldEvent.end).format('HH:mm')}
                =>
                <strong>
                    ${dayjs(arg.event.start).format('YYYY-MM-DD')}
                    ${dayjs(arg.event.start).format('HH:mm')}
                    ${dayjs(arg.event.end).format('HH:mm')}
                </strong>
            </p>
            <p>${Intl.formatMessage({ id: 'AGENDA.APPOINTMENT.UPDATE.ALERT.SCHEDULE.TEXT' })}</p>
        `;
            Swal.fire({
                title: Intl.formatMessage({ id: 'AGENDA.APPOINTMENT.UPDATE.ALERT.SCHEDULE.TITLE' }),
                html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
                cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(updateAppointment(calendarToApiObject(arg.event, userData), arg));
                } else {
                    arg.revert();
                }
            });
        };
        // Microsoft indicator
        useEffect(() => {
            if (!microsoftConnection) {
                return;
            }
            if (microsoftConnection?.result === true) {
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'AGENDA.APPOINTMENT.MICROSOFTOUTLOOK.CONNEXION.SUCCESS' }),
                });
            }
            if (microsoftConnection?.result === false) {
                let variant = 'danger';
                let message = Intl.formatMessage({ id: 'AGENDA.APPOINTMENT.MICROSOFTOUTLOOK.CONNEXION.FAILED' });

                if (microsoftConnection?.message === 'ResourceNotFound') {
                    variant = 'warning';
                    message = Intl.formatMessage(
                        { id: 'AGENDA.APPOINTMENT.MICROSOFTOUTLOOK.CONNEXION.RESOURCE_NOT_FOUND' },
                        { email: teammate?.email || '' },
                    );
                }
                toast({ variant, message });
            }
        }, [microsoftConnection]);

        // User or date change
        useEffect(() => {
            if (currentDate && Object.keys(teammate).length > 0) {
                dispatch(
                    fetchSchedules({
                        userId: teammate.id,
                        userEmail: teammate.email,
                        dateStart: currentDate.dateStart,
                        dateEnd: currentDate.dateEnd,
                    }),
                );
            }
        }, [teammate, currentDate]);

        // data event handler
        useEffect(() => {
            setBusinessHours([]);
            if (availabilities?.errors) {
                setShowModalAlert(true);
            } else {
                Object.keys(availabilities).forEach((availabilityDate) => {
                    const date = new Date(availabilityDate);
                    setBusinessHours((bsHours) => [
                        ...bsHours,
                        availabilities[availabilityDate]?.am
                            ? {
                                  daysOfWeek: [date.getDay()],
                                  startTime: !availabilities[availabilityDate].am.absent
                                      ? availabilities[availabilityDate].am.start
                                      : '00:00',
                                  endTime: !availabilities[availabilityDate].am.absent
                                      ? availabilities[availabilityDate].am.end
                                      : '00:00',
                              }
                            : {},
                        availabilities[availabilityDate]?.pm
                            ? {
                                  daysOfWeek: [date.getDay()],
                                  startTime: !availabilities[availabilityDate].pm.absent
                                      ? availabilities[availabilityDate].pm.start
                                      : '00:00',
                                  endTime: !availabilities[availabilityDate].pm.absent
                                      ? availabilities[availabilityDate].pm.end
                                      : '00:00',
                              }
                            : {},
                    ]);
                });
            }

            setEvents(appointments.map((appointment) => apiToCalendarObject(appointment, teammate, userData)));
        }, [availabilities, appointments]);

        useEffect(() => {
            // default calendar user
            if (defaultVendor)
                setTeammate({
                    id: defaultVendor.id,
                    firstname: defaultVendor.first_name,
                    lastname: defaultVendor.last_name,
                    email: defaultVendor.email,
                });
            else
                setTeammate({
                    id: userData.id,
                    firstname: userData.first_name,
                    lastname: userData.last_name,
                    email: userData.email,
                });
        }, []);

        useEffect(() => {
            if (innerRef?.current) {
                const calendarApi = innerRef.current.getApi();
                calendarApi?.updateSize?.();
            }
        }, [context]);

        const views = useMemo(
            () => ({
                timeGrid: {
                    titleFormat: ({ date: dateStart, end: dateEnd }) => {
                        const start = dayjs(dateStart.marker).format(weekView.titleFormatStart);
                        const end = dayjs(dateEnd.marker).format(weekView.titleFormatEnd);

                        return `${start} ${weekView.titleRangeSeparator} ${end}`;
                    },
                    titleRangeSeparator: weekView.titleRangeSeparator,
                    dayHeaderFormat: ({ date }) => dayjs(date.marker).format(weekView.dayHeaderFormat),
                },
                timeGridWorkWeek: {
                    type: 'timeGrid',
                    buttonText: 'Semaine de travail',
                    dateIncrement: { days: 7 },
                    visibleRange: (now) => {
                        const date = dayjs(now).startOf('week');

                        const startDate = date.toDate();
                        const endDate = date.add(6, 'day').toDate();

                        return { start: startDate, end: endDate };
                    },
                },
            }),
            [],
        );

        return (
            <Card className={className}>
                {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                <Card.Body className={bodyClassName}>
                    {canSelectUser && (
                        <>
                            {!KTUtil.isMobileDevice() ? (
                                <>
                                    <div className="agenda-header">
                                        <Permission permissions={['LEAD_VIEW_TEAM_CALENDAR']}>
                                            <div className="agenda-header-selectors">
                                                <DealershipVendors
                                                    referent={teammate}
                                                    setReferent={setTeammate}
                                                    setReferents={setTeammates}
                                                    dealership={userData?.default_dealership}
                                                />
                                            </div>
                                        </Permission>
                                        <EventCaption />
                                    </div>
                                    <Divider />
                                </>
                            ) : (
                                <div className="mobile-agenda-header">
                                    <Permission permissions={['LEAD_VIEW_TEAM_CALENDAR']}>
                                        <i
                                            className="las la-user font-size-xl"
                                            aria-hidden="true"
                                            onClick={() => setShowMobileDealershipModal(true)}
                                            style={{ color: '#494b73' }}
                                        />
                                    </Permission>
                                    <i
                                        className="las la-info-circle font-size-xl"
                                        aria-hidden="true"
                                        onClick={() => setShowMobileCaptionModal(true)}
                                        style={{ color: '#494b73' }}
                                    />
                                </div>
                            )}
                        </>
                    )}

                    <FullCalendar
                        ref={(ref) => {
                            if (calendar) {
                                calendar.current = ref;
                            }

                            if (innerRef) {
                                innerRef.current = ref;
                            }
                        }}
                        businessHours={businessHours}
                        events={events}
                        eventDisplay="block"
                        eventContent={(arg) => <EventRender event={arg.event} />}
                        eventClick={(info) => {
                            if (
                                !info.event.extendedProps.isPrivate &&
                                hasPermission('LEAD_VIEW_APPOINTMENT_CALENDAR')
                            ) {
                                setEvent(info.event);
                            }
                        }}
                        eventDrop={eventScheduleHandler}
                        eventResize={eventScheduleHandler}
                        dayMaxEvents={2}
                        moreLinkClick="popover"
                        initialView={!KTUtil.isMobileDevice() ? 'timeGridWorkWeek' : 'listWeek'}
                        plugins={[dayGridPlugin, timeGridPlugin, bootstrapPlugin, interactionPlugin, listPlugin]}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            omitZeroMinute: false,
                            meridiem: 'short',
                        }}
                        expandRows
                        themeSystem="bootstrap"
                        headerToolbar={{
                            left: 'prev,today,next',
                            center: 'title',
                            right: !KTUtil.isMobileDevice()
                                ? 'timeGridDay,timeGridWorkWeek,timeGridWeek,dayGridMonth'
                                : 'listWeek,timeGridDay,dayGridMonth',
                        }}
                        views={views}
                        showNonCurrentDates={false}
                        slotEventOverlap={false}
                        nowIndicator
                        selectable={selectable}
                        select={onSelect}
                        navLinks
                        locales={allLocales}
                        locale={Locale}
                        scrollTime="08:30:00"
                        slotMinTime="08:00:00"
                        slotMaxTime="21:00:00"
                        slotLabelInterval="01:00"
                        slotDuration={slotDuration}
                        allDaySlot
                        datesSet={(info) => {
                            const dateStart = new Date(info.view.currentStart);
                            const dateEnd = new Date(info.view.currentEnd);

                            setCurrentDate({
                                dateStart: dateStart.toISOString().split('T')[0],
                                dateEnd: dateEnd.toISOString().split('T')[0],
                            });

                            if (setDatesSetSelected) {
                                setDatesSetSelected({
                                    dateStart: dateStart.toISOString().split('T')[0],
                                    dateEnd: dateEnd.toISOString().split('T')[0],
                                });
                            }
                        }}
                        {...(height ? { height } : {})}
                    />
                </Card.Body>
                <EventModal event={event} user={userData} />
                <ModalAlert
                    show={showModalAlert}
                    setShowModal={setShowModalAlert}
                    redirect={teammate.id === userData.id}
                    history={history}
                />
                <MobileCaptionModal show={showMobileCaptionModal} setShow={setShowMobileCaptionModal} />
                <MobileDealershipVendorModal
                    show={showMobileDealershipModal}
                    setShow={setShowMobileDealershipModal}
                    teammate={teammate}
                    setTeammate={setTeammate}
                    setTeammates={setTeammates}
                    dealership={userData?.default_dealership}
                />
            </Card>
        );
    },
);

Agenda.defaultProps = {
    className: '',
    bodyClassName: '',
    canSelectUser: true,
    selectable: false,
    onSelect: () => {},
    slotDuration: '00:15:00',
    height: 'auto',
    weekView: {
        titleFormatStart: 'D',
        titleFormatEnd: 'D MMMM YYYY',
        titleRangeSeparator: ' au ',
        dayHeaderFormat: 'dddd DD MMMM',
    },
};

export default Agenda;
